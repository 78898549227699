<template>
  <div>
    <div v-show="!aa">
      <div class="risk_warning_div flex_center_start_box">
        <div class="iconfont icon-jinggao"></div>
        <div class="risk_warning_tit">风险预警:</div>
        <div v-if="listName">
          <div class="warp1" v-for="item in newListName" :key="item.index">{{item}}</div>
        </div>
        <vue-seamless-scroll :data="newListName" :class-option="optionLeft" class="seamless-warp2" v-else>
          <!-- <div class="all_l">{{newsList}}{{newsList}}</div> -->
          <div class="flex_center_start_box">
            <div v-for="item in newListName" :key="item" class="risk_warning_div_item">{{item}}</div>
          </div>
        </vue-seamless-scroll>
      </div>
      <div class="workbench_table_title_total_div">
        <div class="workbench_table_title_total workbench_table_title_total_top flex_box">
          <div class="workbench_table_title_total_bottom_dashed">
            <div class="flex_box">
              <div class="workbench_table_title_total_tit">逾期融资总数(笔):</div>
              <div class="workbench_table_title_total_button">{{overdueFinancingAccount}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1 yellow_font">拖欠融资总数(笔):</div>
              <div class="workbench_table_title_total_button yellow">{{defaultFinancingAccount}}</div>
              <div class="vertical_line"></div>
            </div>
            <div class="flex_box">
              <div class="workbench_table_title_total_tit1 grey_font">坏账融资总数(笔):</div>
              <div class="workbench_table_title_total_button grey">{{badDebtFinancingAccount}}</div>
              <div class="vertical_line"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="table_common">
        <div class="search_flex_box">
          <div class="search_condition search_condition_right">
            <el-form ref="financingForm1" :model="financingForms1">
              <el-form-item label="融资编号:" class="financing_number financing_border" prop="financingNum">
                <el-input v-model="financingForms1.financingNum" @input="searchInput" maxlength="20">
                  <template slot="prepend">FN</template>
                </el-input>
              </el-form-item>
              <el-form-item label="融资企业名称:" prop="financingName">
                <el-input v-model="financingForms1.financingName"></el-input>
              </el-form-item>
              <el-form-item label="融资企业类型:" prop="financingEnterpriseType">
                <el-select v-model="financingForms1.financingEnterpriseType" placeholder="" class="select_class">
                  <el-option v-for="item in  companyState" :key="item.index" :label="item.value" :value="item.codeState"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="逾期天数:" prop="overdueDays">
                <el-input v-model="financingForms1.overdueDays" @input="searchday" maxlength="4"></el-input>
                <!-- <el-input v-model="financingForms1.data"></el-input> -->
              </el-form-item>
              <el-form-item label="风险类型:" class="data_item" prop="riskType">
                <el-select v-model="financingForms1.riskType" placeholder="" class="select_class">
                  <el-option v-for="item in  accountsState" :key="item.index" :label="item.value" :value="item.codeState"></el-option>
                </el-select>
                <!-- <el-input v-model="financingForms1.money"></el-input> -->
              </el-form-item>
            </el-form>
          </div>
          <div class="search_boxs flex_center_between_box">
            <el-tooltip popper-class="search" effect="light" content="搜索" placement="top">
              <div class="search_button_box" @click="searchForm()">
                <i class="iconfont icon-10sousuo"></i>
              </div>
            </el-tooltip>
            <el-tooltip popper-class="search" effect="light" content="重置" placement="top">
              <div class="reset_button_box" @click="resetButton()">
                <i class="iconfont icon-shuaxin"></i>
              </div>
            </el-tooltip>
          </div>
        </div>
        <div class="table_box">
          <div class="table_box_top flex_center_between_box">
            <div class="table_box_top_title">我的风险池</div>
            <div class="flex_center_end_box">
              <div class="search_button button data_export">数据导出</div>
            </div>
          </div>
          <div class="table_box_bottom">
            <el-table :data="tableData" stripe border style="width: 100%">
              <el-table-column label="融资编号" prop="financingNum" align="center" :resizable="false" :width="width180">
              </el-table-column>
              <el-table-column label="融资企业名称" prop="financingName" align="left" header-align="center" show-overflow-tooltip :resizable="false" :width="nameWidth">
                <template slot-scope="scope">
                  <div class="link" @click="detailIndex(scope.row)">{{scope.row.financingName}}</div>
                </template>
              </el-table-column>
              <el-table-column label="融资企业类型" prop="financingEnterpriseType" align="center" show-overflow-tooltip :resizable="false" :width="width150" />
              <el-table-column label="融资企业评级" prop="financingEnterpriseGrade" align="center" show-overflow-tooltip :resizable="false" :width="width150">
                <template slot-scope="scope">
                  <div v-if="scope.row.creditRisk == 0">{{scope.row.financingEnterpriseGrade}} </div>
                  <div class="flex_center_between_box companyGrade" v-else>
                    <div class="">{{scope.row.financingEnterpriseGrade}} </div>
                    <div class="flex_center_between_box companyGradeList">
                      <i class="iconfont icon-web-icon-" v-if="scope.row.creditRisk > 0"></i>
                      <i class="iconfont icon-web-icon-1" v-else></i>
                      <span v-if="scope.row.creditRisk < 0" class="compare_color1">{{scope.row.creditRisk | PositiveNumber}}</span>
                      <span v-else class="compare_color2">{{scope.row.creditRisk}}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="投资金额" prop="managementFinancingAmount" align="right" header-align="center" show-overflow-tooltip :resizable="false">
                <template slot-scope="scope">{{scope.row.managementFinancingAmount | NumFormat}}</template>
              </el-table-column>
              <el-table-column label="逾期天数" prop="overdueDays" align="center" sortable show-overflow-tooltip :resizable="false" />
              <!-- <el-table-column label="账款状态" prop="invoiceMoney" align="center" header-align="center" show-overflow-tooltip :resizable="false"/> -->
              <el-table-column label="风险类型" prop="riskType" align="center" header-align="center" show-overflow-tooltip :resizable="false">
                <template slot-scope="scope">
                  <div v-if="scope.row.riskType == 1">逾期</div>
                  <div v-else-if="scope.row.riskType == 2">拖欠</div>
                  <div v-else-if="scope.row.riskType == 3">坏账</div>
                  <div v-else>风险预警</div>
                </template>
              </el-table-column>
              <el-table-column label="操 作" prop="operation" align="center" :resizable="false">
                <template slot-scope="scope">
                  <div class="table_oper flex_center">
                    <el-tooltip class="item_color_money" effect="light" content="贷款详情" placement="top">
                      <i class="iconfont icon-xiangqingye-35" @click="detailDialog(scope.row,scope.$index)"></i>
                    </el-tooltip>
                    <el-tooltip class="item_color" effect="light" content="服务" placement="top">
                      <i class="iconfont icon--fuwu-xianxing"></i>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <Page :totalFont=true :total=page.total :currentPage='page.pageNo' @onPageChange="onPageChange"></Page>
        </div>
      </div>
    </div>
    <div v-show="aa">
      <!-- <div class="second_return_icon" @click="returnButton()">
                <i class="iconfont icon-fanhui" ></i>
                融资企业详情
            </div> -->
      <Company ref="Company"></Company>
    </div>
    <!-- 发票详情弹框 -->
    <el-dialog title="风险池详情" :visible.sync="invoiceDetailDialog" center class="dialog_width500 dialog_add_edit_form width_1100" :close-on-click-modal="false">
      <div class="waiting_signing">逾期/拖欠/坏账</div>
      <div class="width_1100_left1">
        <div class="add_invoice_message">
          <el-form :model="financingForms" refs="financingForms">
            <el-form-item label="融资编号:">
              <el-input v-model="financingForms.financingNum" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资企业名称:">
              <el-input v-model="financingForms.financingName" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资企业类型:">
              <el-input v-model="financingForms.financingEnterpriseType" disabled></el-input>
            </el-form-item>
            <el-form-item label="融资企业评级:">
              <el-input v-model="financingForms.financingEnterpriseGrade" disabled></el-input>
            </el-form-item>
            <el-form-item label="投资金额:">
              <el-input v-model="financingForms.managementFinancingAmount" disabled></el-input>
            </el-form-item>
            <el-form-item label="逾期天数:">
              <el-input v-model="financingForms.overdueDays" disabled></el-input>
            </el-form-item>
            <el-form-item label="账款状态:">
              <el-input v-model="financingForms.state1" disabled></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <dir class="width_1100_right2">
        <div class="add_invoice_message">
          <div class="table_tit">申请融资发票详情:</div>
          <el-table class="dialog_table" :data="tableData1" stripe border style="width: 100%">
            <el-table-column label="发票编号" prop="invoiceNo" align="center" show-overflow-tooltip :resizable="false" :width="dialogidWidth" />
            <el-table-column label="发票日期" prop="invoiceDate" align="center" show-overflow-tooltip :resizable="false" />
            <el-table-column label="发票金额" prop="invoiceAmount" align="right" header-align="center" show-overflow-tooltip :resizable="false">
              <template slot-scope="scope">
                {{scope.row.invoiceAmount | NumFormat}}
              </template>
            </el-table-column>
            <el-table-column label="发票账期" prop="accPeriod" align="center" show-overflow-tooltip :resizable="false" />
            <el-table-column label="账款到期日" prop="invoiceDateEnd" align="center" header-align="center" show-overflow-tooltip :resizable="false" :width="dialogWidth" />
          </el-table>
        </div>
      </dir>
      <span slot="footer" class="dialog-footer">
        <el-button class="footer_button1" type="primary" @click="invoiceDetailDialog = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Company from '../../supplier/components/companyDetail.vue'
import { mixins1 } from "@/mixins/index";
import Page from '@/components/page'
import vueSeamlessScroll from 'vue-seamless-scroll'
import { getManageInvestmentList, findCodeInfoByKey, getManageInvestmentInfoById, findRiskCount, findInvestmentCounts, findRiskWarning } from '@/api/investor.js'
export default {
  mixins: [mixins1],
  components: {
    Page, vueSeamlessScroll, Company
  },
  data() {
    return {
      aa: false,
      newsList: '',
      newListName: [],
      financingForms: {
        financingNum: '',
        companyName: '',
        companyState: '',
        companyGrade: '',
        managementFinancingAmount: '',
        invoiceGrade: '',
        state1: '',
      },
      financingForms1: {
        financingNum: '',
        financingName: '',
        financingEnterpriseType: '',
        overdueDays: '',
        riskType: ''
      },
      tableData: [
      ],
      tableData1: [
        {
          id: '',
          data: '',
          money: '',
          accounting: '',
          dataEnd: ''
        },
      ],
      tableData2: [
        {
          id: '02963294',
          data: '2021/11/01',
          money: '15158',
          accounting: '30',
          dataEnd: '2021/10/28'
        },
      ],
      tableData3: [
        {
          id: '21960922',
          data: '2021/11/05',
          money: '99000',
          accounting: '60',
          dataEnd: '2021/11/01'
        },
      ],
      invoiceDetailDialog: false,
      classChange: false,
      supplierNum: 0,
      coreEnterPriseNum: 0,
      dialogWidth: 100,
      dialogidWidth: 94,
      page: {
        pageNo: 1,
        pageSize: 8,
        total: 0
      },
      companyState: [],
      accountsState: [],
      overdueFinancingAccount: 0,
      defaultFinancingAccount: 0,
      badDebtFinancingAccount: 0,
      listName: true,
      financingNameList: []
    }
  },
  created() {
    this.tableWidth()
    this.resizeFn()
    this.getData()
    this.ficationList1()
    this.findRiskWarningList()
  },
  computed: {
    optionLeft() {
      return {
        step: 1,
        direction: 2,
        // limitMoveNum: 1,
        openWatch: true,
        // singleWidth: 1000,  
      }
    }
  },
  methods: {
    searchday(val) {
      val = val.replace(/[^\d]/g, '')
      this.financingForms1.overdueDays = val
    },
    searchInput(val) {
      val = val.replace(/[^\d]/g, '')
      this.financingForms1.financingNum = val
    },
    async getData() {
      let res = await getManageInvestmentList('pageNo=' + this.page.pageNo + '&pageSize=' + this.page.pageSize + '&ms=' + 1 +
        '&financingNum=' + this.financingForms1.financingNum + '&financingName=' + this.financingForms1.financingName + '&financingEnterpriseType=' +
        this.financingForms1.financingEnterpriseType + '&overdueDays=' + this.financingForms1.overdueDays + '&riskType=' + this.financingForms1.riskType)
      this.tableData = res.data.records
      this.page.total = res.data.total
      res = await findRiskCount()
      this.overdueFinancingAccount = res.data.overdue
      this.defaultFinancingAccount = res.data.fall_behind
      this.badDebtFinancingAccount = res.data.bad_loan
    },
    async findRiskWarningList() {
      let res = await findRiskWarning()
      res.data.forEach((item) => {
        this.newListName.push(item.financingName)
      })
      this.newListName = this.newListName.toString()
      let cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/g
      let mat = this.newListName.match(cnReg)
      let length
      if (mat) {
        length = (mat.length + (this.newListName.length - mat.length) * 0.5)
        if (length > 98) {
          this.listName = false
        } else {
          this.listName = true
        }
      } else {
        return this.newListName.length * 0.5
      }
    },
    async ficationList1() {
      let res = await findCodeInfoByKey('ms=' + 28)
      this.companyState = res.data
      res = await findCodeInfoByKey('ms=' + 10)
      this.accountsState = res.data
    },
    searchForm() {
      this.page.pageNo = 1
      this.getData()
    },
    resetButton() {
      this.$refs.financingForm1.resetFields()
      this.page.pageNo = 1
      this.getData()
    },
    resizeFn() {
      if (document.body.clientWidth > 1664) {
        this.dialogWidth = 100
        this.fontSize = 14
        this.echartsWidth = 36
        this.dialogidWidth = 85
        this.width180 = 180
      } else {
        this.dialogWidth = 69
        this.fontSize = 10
        this.echartsWidth = 24
        this.dialogidWidth = 63
        this.width180 = 130
      }
      // this.$nextTick(function(){
      //     this.riskEchart2(this.fontSize,this.echartsWidth)
      // })
    },
    async detailDialog(row, rowIndex) {
      let res = await getManageInvestmentInfoById('Id=' + row.id)
      this.tableData1 = res.data.invoiceEntities
      this.financingForms = row
      this.invoiceDetailDialog = true
      this.financingForms.managementFinancingAmount = this.filter(this.financingForms.managementFinancingAmount)
      this.financingForms.state = row.state
      if (row.state1 == 0) {
        this.financingForms.state1 = '未确认 '
      }
      else {
        this.financingForms.state1 = '已确认'
      }
    },
    detailIndex(row) {
      this.$refs.Company.getCompany(row.financingName)
      this.aa = true
      // this.top()
    },
  }
}
</script>

<style scoped>
  .companyGrade {
    padding: 0 18px;
  }
  .companyGradeList {
    width: 36px;
  }
  .warp1 {
    float: left;
    /* width: 100%; */
    height: 40px;
    line-height: 43px;
    font-size: 14px;
    color: #162747;
  }
  .seamless-warp2 {
    overflow: hidden;
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #162747;
  }
  .icon-jinggao {
    color: #ff9b15;
    margin-right: 4px;
  }

  .risk_warning_div {
    width: 100%;
    height: 40px;
    margin-bottom: 14px;
    padding-left: 20px;
    background: #edf2f5;
    border-radius: 10px;
  }

  .risk_warning_tit {
    font-size: 14px;
    font-family: 'Microsoft YaHei Bold';
    color: #ff9b15;
    width: 65px;
    margin-right: 10px;
  }

  .yellow {
    background-color: #ff9b15;
  }
  .grey {
    background-color: #7c969b;
  }
  .yellow_font {
    color: #ff9b15;
  }
  .grey_font {
    color: #7c969b;
  }
  ::v-deep .dialog_table.el-table th > .cell {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  ::v-deep .dialog_table.el-table th {
    font-size: 16px;
    height: 40px;
  }
  .dialog_table {
    /* max-height: 242px; */
    margin-bottom: 20px;
  }
  .table_tit {
    line-height: 40px;
    font-size: 14px;
    color: #162747;
  }
  ::v-deep .search_condition .data_item .el-form-item__label {
    min-width: 97px;
  }
  .workbench_table_title_total {
    background-color: transparent;
  }
  .workbench_table_title_total_div {
    box-shadow: 0px 0px 5px 0px rgba(9, 44, 55, 0.28) inset;
    background-color: #edf2f5;
    border-radius: 10px;
    margin-bottom: 30px;
  }
  .workbench_table_title_total_tit {
    width: 225px;
  }
  .workbench_table_title_total_tit1 {
    width: 340px;
  }
  .apply_box_top_left {
    width: 394px;
    height: 306px;
    padding-top: 30px;
    padding-left: 40px;
  }
  .class_change1 {
    background-image: url(../../../../assets/img/system/investor/echarts_tab.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;
  }
  .class_change2 {
    background-image: url(../../../../assets/img/system/investor/echarts_tab1.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top center;
  }

  .apply_box_total {
    display: flex;
    align-items: center;
    width: 300px;
    height: 108px;
    padding-top: 20px;
    padding-bottom: 24px;
    padding-left: 16px;
    background: rgba(237, 242, 245, 0.2);
    box-shadow: 0px 4px 8px 0px rgba(0, 102, 101, 0.23);
    border-radius: 14px;
    cursor: pointer;
  }
  .apply_bottom {
    margin-bottom: 10px;
  }
  .echarts_box {
    height: 350px;
    margin-top: -40px;
  }
  .search_flex_box >>> .el-form-item {
    margin-bottom: 20px;
  }
  .search_flex_box >>> .el-form-item:last-child {
    margin-bottom: 0;
  }
  .search_flex_box >>> .el-form-item:nth-child(3) {
    margin-right: 0;
  }
  .search_condition {
    flex: 1;
  }
  .search_condition_right >>> .el-form-item {
    margin-right: 42px;
  }
  /* 弹框 */
  .second_return_icon {
    margin-left: 20px;
    cursor: pointer;
  }
  .second_return_icon i {
    margin-right: 24px;
    font-size: 20px;
  }
  @media screen and (max-width: 1664px) {
    .companyGrade {
      padding: 0 12px;
    }
    .companyGradeList {
      width: 24px;
    }
    .seamless-warp2 {
      overflow: hidden;
      width: 100%;
      height: 27px;
      line-height: 27px;
      font-size: 12px;
      color: #162747;
    }
    .icon-jinggao {
      color: #ff9b15;
      margin-right: 3px;
    }

    .risk_warning_div {
      width: 100%;
      height: 27px;
      margin-bottom: 9px;
      padding-left: 13px;
      background: #edf2f5;
      border-radius: 7px;
    }

    .risk_warning_tit {
      font-size: 12px;
      font-family: 'Microsoft YaHei Bold';
      color: #ff9b15;
      width: 57px;
      margin-right: 7px;
    }

    .yellow {
      background-color: #ff9b15;
    }
    .grey {
      background-color: #7c969b;
    }
    .yellow_font {
      color: #ff9b15;
    }
    .grey_font {
      color: #7c969b;
    }
    ::v-deep .dialog_table.el-table th > .cell {
      padding-left: 4px !important;
      padding-right: 4px !important;
    }
    ::v-deep .dialog_table.el-table th {
      font-size: 12px;
      height: 27px;
    }
    .dialog_table {
      /* max-height: 242px; */
      margin-bottom: 13px;
    }
    .table_tit {
      line-height: 27px;
      font-size: 12px;
      color: #162747;
    }
    ::v-deep .search_condition .data_item .el-form-item__label {
      min-width: 81px;
    }
    .workbench_table_title_total {
      background-color: transparent;
    }
    .workbench_table_title_total_div {
      box-shadow: 0px 0px 3px 0px rgba(9, 44, 55, 0.28) inset;
      background-color: #edf2f5;
      border-radius: 7px;
      margin-bottom: 20px;
    }
    .workbench_table_title_total_tit {
      width: 150px;
    }
    .workbench_table_title_total_tit1 {
      width: 227px;
    }
    .apply_box_top_left {
      width: 263px;
      height: 204px;
      padding-top: 20px;
      padding-left: 27px;
    }
    .class_change1 {
      background-image: url(../../../../assets/img/system/investor/echarts_tab.png);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: top center;
    }
    .class_change2 {
      background-image: url(../../../../assets/img/system/investor/echarts_tab1.png);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: top center;
    }

    .apply_box_total {
      display: flex;
      align-items: center;
      width: 200px;
      height: 72px;
      padding-top: 13px;
      padding-bottom: 16px;
      padding-left: 11px;
      background: rgba(237, 242, 245, 0.2);
      box-shadow: 0px 3px 5px 0px rgba(0, 102, 101, 0.23);
      border-radius: 9px;
      cursor: pointer;
    }
    .apply_bottom {
      margin-bottom: 7px;
    }
    .echarts_box {
      height: 233px;
      margin-top: -27px;
    }
    .search_flex_box >>> .el-form-item {
      margin-bottom: 13px;
    }
    .search_flex_box >>> .el-form-item:last-child {
      margin-bottom: 0;
    }
    .search_flex_box >>> .el-form-item:nth-child(3) {
      margin-right: 0;
    }
    .search_condition {
      flex: 1;
    }
    .search_condition_right >>> .el-form-item {
      margin-right: 11px;
    }
    /* 弹框 */
    .second_return_icon {
      margin-left: 13px;
      cursor: pointer;
    }
    .second_return_icon i {
      margin-right: 16px;
      font-size: 13px;
    }
  }
</style>